<template>
    <div id="questionsList"
        class="unit-section">

    </div>
</template>

<script lang="js">
export default {
    name: 'splash',
}
</script>
