<template>
    <router-view />
</template>


<script>
import Vue from 'vue'
export default {

}
</script>
