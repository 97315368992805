<template>
    <div class="row">
        <div class="col-xs-12">
            <div>
                <h1>
                    {{ $t('OutdatedBrowser.HellowText') }}
                </h1>
                <h3>
                    {{ $t('OutdatedBrowser.PleaseUpdate') }}
                </h3>
                <p>
                    {{ $t('OutdatedBrowser.LinkToUpdate') }} <a href="https://browser-update.org/update.html">browser-update.org</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {

}

</script>
