<template>
    <div>
        {{ $config.model.errorMessage }}
    </div>
</template>

<script>

export default {

}

</script>
